import store from "./store";
import { storage } from "firebase";
import firebase from "firebase";
import axios from "axios";

export function getAxiosHTTP(token) {
  return axios.create({
    baseURL: `https://med-prod-central.uc.r.appspot.com`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });
}

export async function callGetAPIWithNoAuth(endpoint, key, dataToPass) {
  const token = 'notoken'
  var outputs = [];
  var http = getAxiosHTTP(token);
  var resp = await http.get(endpoint, { params: dataToPass });
  let data = JSON.parse(resp.data);
  if (typeof data == "string") return data;
  if (typeof data == "object"){
    outputs.push(data);
    return outputs;
  }
  for (let index = 0; index < data.length; index++) {
    if (!key) {
      outputs.push({ ...data[index], id: data[index][data[index].id] });
    } else {
      outputs.push({ ...data[index], id: data[index][key] });
    }
  }
  return outputs;
}

export async function callGetAPI(endpoint, key, dataToPass) {
  const token = await firebase.auth().currentUser.getIdToken(true);
  var outputs = [];
  var http = getAxiosHTTP(token);
  var resp = await http.get(endpoint, { params: dataToPass });
  let data = JSON.parse(resp.data);
  if (typeof data == "string") return data;
  for (let index = 0; index < data.length; index++) {
    if (!key) {
      outputs.push({ ...data[index], id: data[index][data[index].id] });
    } else {
      outputs.push({ ...data[index], id: data[index][key] });
    }
  }
  return outputs;
}

export async function callPostAPI(endpoint, key, dataToPass, returnRequest) {
  var outputs = [];
  const token = await firebase.auth().currentUser.getIdToken(true);
  var http = getAxiosHTTP(token);
  var resp = await http.post(endpoint, dataToPass);
  resp._data = resp.data;
  resp.data = JSON.parse(resp._data);
  if (returnRequest) {
    return resp;
  }
  return resp.data;
}

export async function callDeleteAPI(endpoint, key, dataToPass) {
  var outputs = [];
  const token = await firebase.auth().currentUser.getIdToken(true);
  var http = getAxiosHTTP(token);
  var resp = await http.delete(endpoint, { params: dataToPass });
  let data = JSON.parse(resp.data);
  return data;
}

export async function createUser(userId, firebaseAuthUser){
  console.log(firebaseAuthUser.providerData);
  const providerData = firebaseAuthUser.providerData[0];
  const { displayName, photoURL, email, providerId } = providerData;
  var dataToPass = JSON.stringify({"email": email, "displayName": displayName, "avatar": photoURL, "providerId": providerId, "type": "patient"});
  var data = await callPostAPI('users', null , dataToPass)
  console.log(data);
  return data;
}

export async function AppSettings() {
  const token = await firebase.auth().currentUser.getIdToken(true);
  var dataToPass = JSON.stringify({});
  var outputs = [];
  var http = getAxiosHTTP(token);
  var resp = await http.get("appsettings", { params: dataToPass });
  let data = JSON.parse(resp.data);
  return data;
}

export function getUser() {
  return store.state.authentication.user;
}
export function setUser(user) {
  return localStorage.getItem("user", JSON.stringify(user));
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function uploadToStorageAndGetURI(bucketRef, file) {
  return storage()
    .ref(bucketRef)
    .put(file)
    .then(function(snapshot) {
      return snapshot.ref.getDownloadURL().then(function(url) {
        return url;
      });
    });
}

export const AUthDetailConvertingLayer = {
  encode(val) {
    return JSON.stringify(val);
  },
  decode(str = "{}") {
    return JSON.parse(str);
  }
};
