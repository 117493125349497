<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="toggleNav" v-if="isNavOpen"></div>
    <transition name="slide">
      <div v-if="isNavOpen" class="sidebar-panel">
        <ul class="sidebar-panel-nav">
          <div v-if="user" class="list-container">
            <div v-if="user.type=='patient'">
              <p class="list-text">User-{{ user.id.substr(user.id.length - 8) }}</p>
            </div>
            <div v-else>
              <p class="list-text">{{ user.firstName }}</p>
            </div>
          </div>
          <li class="list">
            <div @click="clickLink('/')" class="list-container">
              <p class="list-text">Home</p>
            </div>
          </li>
          <li>
            <div @click="clickLink('/experts')" class="list-container">
              <p class="list-text">Our Experts</p>
            </div>
          </li>
          <li>
            <div @click="clickLink('/contactus')" class="list-container">
              <p class="list-text">Contact Us</p>
            </div>
          </li>
          <li>
            <div @click="clickLink('/faq')" class="list-container">
              <p class="list-text">FAQs</p>
            </div>
          </li>
          <li>
            <div v-if="!user" @click="clickLink('/expertcontact')" class="list-container">
              <p class="list-text">Join as Expert</p>
            </div>
            <div
              v-else-if="user.type == 'expert'"
              @click="clickLink('/expert-queries')"
              class="list-container"
            >
              <p class="list-text">My Queries</p>
            </div>
            <div v-else @click="clickLink('/queries')" class="list-container">
              <p class="list-text">My Queries</p>
            </div>
            <div
              v-if="user && user.type != 'expert'"
              @click="clickLink('/expertcontact')"
              class="list-container"
            >
              <p class="list-text">Join as Expert</p>
            </div>
          </li>
          <li>
            <div v-if="user" @click="logout" class="list-container">
              <p class="list-text">Logout</p>
            </div>
            <div v-else @click="clickLink('/ask-auth')" class="list-container">
              <p class="list-text">Login</p>
            </div>
          </li>
        </ul>
        <div class="socialIconsContainer">
          <a href="https://twitter.com/mednosisllc" target="_blank">
            <img src="@/assets/twitter.png" />
          </a>
          <a href="https://www.linkedin.com/company/mednosis" target="_blank">
            <img src="@/assets/linkden.png" />
          </a>
          <a href="https://www.facebook.com/mednosis1/" target="_blank">
            <img src="@/assets/faceebook.png" />
          </a>
        </div>
        <!-- <ul class="sidebar-panel-nav">
          
        </ul>-->
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import firebase from "firebase/app";

export default {
  methods: {
    clickLink(path) {
      this.toggleNav();
      this.$router.push(path);
    },
    async logout() {
      await firebase.auth().signOut();
      localStorage.removeItem("user");
    },
    ...mapMutations("app", ["toggleNav"]),
  },
  computed: {
    ...mapState("app", ["isNavOpen"]),
    ...mapState("authentication", ["user"]),
  },
};
</script>
<style lang="scss" scoped>
@import "@/theme/variables.scss";

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(19, 15, 64, 0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #ffffff;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  /* padding: 3rem 20px 2rem 20px; */
  overflow-y: scroll;
  width: 300px;
}

.sidebar-panel-nav {
  list-style: none;
  margin-right: 50px;
  margin-top: 50px;
}

.list-container {
  /* background-color: #00abdc; */
  margin-top: 10px;
  border-bottom: solid;
  border-width: thin;
  padding-bottom: 10px;
}

.socialIconsContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
  margin-bottom: 50px;
}

.list-text {
  font-family: $header-title;
  font-size: 24px;
}
</style>
