import Vue from "vue";
import Router from "vue-router";
import Head from "vue-head";
import Home from "@/views/Home";
import { isNil } from "lodash";
import store from "@/store";

Vue.use(Router);

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
});

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/terms",
      name: "terms",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-Terms" */ "@/views/Terms.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-Privacy" */ "@/views/Privacy.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/investors",
      name: "investors",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-InvestorSection" */ "@/views/InvestorSection.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/faq",
      name: "faq",
      component: () =>
        import(/* webpackChunkName: "client-chunk-FAQ" */ "@/views/FAQ.vue"),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/contactus",
      name: "contactus",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-ContactUs" */ "@/views/ContactUs.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/check-login",
      name: "check-login",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-CheckLogin" */ "@/views/CheckLogin.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/ask-auth",
      name: "ask-auth",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-AskAuth" */ "@/views/AskAuth.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/signup",
      name: "register",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-Register" */ "@/views/Register.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ "@/views/Login.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/reset",
      name: "reset",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ "@/views/Reset.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: "/queries",
      name: "queries",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-Queries" */ "@/views/Queries.vue"
        ),
      meta: {
        onlyFor: "patient"
      }
    },
    // {
    //   path: "/payment",
    //   name: "payment",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "client-chunk-Queries" */ "@/views/Payment.vue"
    //     ),
    //   meta: {
    //     onlyFor: "patient"
    //   }
    // },
    {
      path: "/expert-queries",
      name: "expert-queries",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-ExpertQueries" */ "@/views/ExpertQueries.vue"
        ),
      meta: {
        onlyFor: "expert"
      }
    },
    {
      path: "/ask-expert/:id",
      name: "ask-expert",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-AskExpert" */ "@/views/AskExpert.vue"
        ),
      meta: {
        onlyFor: "patient"
      }
    },
    {
      path: "/profile/:id",
      name: "profile",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-Profile" */ "@/views/Profile.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: `/queryChat/:id`,
      name: "queryChat",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-QueryCha" */ "@/views/QueryChat.vue"
        )
    },
    {
      path: `/queryChat/:id/close`,
      name: "queryChatClose",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-QueryCha" */ "@/views/CloseChat.vue"
        )
    },
    {
      path: `/experts`,
      name: "experts",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-OurExperts" */ "@/views/OurExperts.vue"
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: `/expertcontact`,
      name: "expertcontact",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-ExpertContact" */ "@/views/ExpertContact.vue"
        )
    },
    {
      path: "/filters",
      name: "filters",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-Filters" */ "@/views/Filters.vue"
        ),
      meta: {
        onlyFor: "patient"
      }
    },
    {
      path: "/notification",
      name: "notification",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-Notification" */ "@/views/Notification.vue"
        ),
      meta: {
        onlyFor: "patient"
      }
    },
    {
      path: "/add-query",
      name: "addQuery",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-NewQuery" */ "@/views/NewQuery.vue"
        ),
      meta: {
        onlyFor: "patient"
      }
    },
    { path: "*", redirect: "/" }
  ]
});
/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (
    (!to.meta || !to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
    store.state.authentication.user === null ? "/ask-auth" : "/check-login";
    console.log(
      store.state.authentication.user,
      path,
      `${path}?redirectUrl=${to.path}`
    );
    return next(`${path}?redirectUrl=${to.path}`);
  } else if (
    to.meta &&
    to.meta.onlyFor &&
    store.state.authentication.user.type !== to.meta.onlyFor
  ) {
    return next(`/`);
  }
  next();
});

export default router;
