import Vue from "vue";
import VueAnalytics from 'vue-analytics';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faCopy,
  faImages,
  faVideo,
  faPaperclip,
  faSpinner,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/misc/register-service-worker";
import "@/misc/handle-network-status";
import "@/firebase/init";
import "@/firebase/authentication";
import "@/misc/handle-apple-install-prompt";
import "pwacompat";
library.add(faUserSecret);
library.add(faCopy);
library.add(faImages);
library.add(faVideo);
library.add(faPaperclip);
library.add(faSpinner);
library.add(faPaperPlane);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueAnalytics, {
  id: ['UA-155294599-1', 'UA-155294599-2'],
  autoTracking: {
    screenview: true,
    pageviewOnLoad: false
  },
  router
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

console.log(`
All Copyrights to Mednosis LLC.
`);
