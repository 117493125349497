<template>
  <div>
    <div class="body">
      <p class="heading">Featured experts</p>
      <h3 v-if="loading" class="text-center">Loading...</h3>
      <h3 v-else-if="error" class="text-center">{{ error }}</h3>
      <h3 v-else-if="!experts.length" class="text-center">
        Don't have any experts Yet
      </h3>
      <div class="grid">
        <div
          v-for="expert in experts"
          @click="$emit('select', expert)"
          class="card"
        >
          <img
            class="avatar"
            :src="expert.avatar || '@/assets/expertIcon.png'"
          />
          <div class="infos">
            <div class="name capitalize">
              {{ expert.firstName }} {{ expert.lastName }}, {{ expert.profession }}
            </div>
            <div v-if="expert.specialities && expert.specialities.length" class="subHeading">
              <b>Specialities:</b>
              {{ expert.specialities.join(" - ") }}
            </div>
            <button
              @click="$router.push(`/ask-expert/${expert.id}`)"
              class="ask-button"
              v-if="!hideAskQuestionButton"
            >ASK QUESTION
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { isNil } from "lodash";
import firebase from "firebase";
import TabBar from "@/components/TabBar.vue";
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Menu/Sidebar.vue";
import { getUser } from "@/utils";
import QueryCard from "@/components/QueryCard.vue";
import { desktop as isDekstop } from "is_js";

export default {
  props: {
    label: {
      required: true,
      type: String
    },
    hideAskQuestionButton: {
      default: false,
      type: Boolean
    }
  },
  components: {
    TabBar,
    Header,
    QueryCard,
    Sidebar
  },
  computed: mapState("app", ["appTitle", "isMobile"]),
  data: () => ({ error: null, experts: [], loading: false }),
  created() {
    this.loading = true;
    const db = firebase.firestore();
    db.collection("users")
      .where("type", "==", "expert")
      .where("initialConsultationFee", "==", 10)
      .onSnapshot(snapshot => {
        let experts = [];
        snapshot.forEach(doc => {
          let expert = doc.data();
          if (!expert.disabled && expert.initialConsultationFee) experts.push({ ...expert, id: doc.id });
        });
        this.experts = experts;
        this.loading = false;
      });
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

// * {
//   margin-top: 1px;
//   padding: 0;
// }

$global_gap: 10px;
$shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$shadow_hover: 0 6px 10px rgba(0, 0, 0, 0.16), 0 6px 10px rgba(0, 0, 0, 0.23);

.headerContainer {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  justify-content: space-between;
  border-bottom: solid;
  border-width: thin;
  border-color: lightgrey;
  background-color: #ffffff;
  align-items: center;
  width: 100%;
  padding-top: 10px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
  }

  .icon {
    margin-left: 10px;
  }
}

.body {
  margin: 80px 25px 0 25px;

  .heading {
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  .grid {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    // margin-left: 20px;
    // justify-content: space-evenly;
    .card {
      width: calc(50% - 40px);
      margin: 10px;
      min-height: 185px;

      display: flex;

      padding: $global_gap;
      border-radius: 5px;
      box-shadow: $shadow;
      background: rgba(255, 255, 255, 0.8);

      .avatar {
        width: 100px;
        height: 100px;
        margin-right: $global_gap;
        border-radius: 50%;
        //box-shadow: $shadow;
        //border: 4px solid rgba(255, 255, 255, .5);
      }

      .infos {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        .ask-button {
          padding: 5px;
          color: white;
          background-color: black;
          border: none;
          border-radius: 5px;
          margin-top: 20px;
        }
        div {
          &:not(:last-child) {
            margin-bottom: $global_gap;
          }

          &.capitalize {
            text-transform: capitalize;
          }

          &.name {
            font-size: 100%;
            font-weight: bold;
          }

          i {
            margin-right: $global_gap;
          }
        }
      }
    }

    @media only screen and (max-width: 800px) {
      .card {
        width: calc(100% - 40px);
        min-height: 150px;
      }
    }
  }
}
</style>
