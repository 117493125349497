<template>
  <div class="headerContainer">
    <p class="title">{{title}}</p>
    <Sidebar />
    <span class="icon" v-if="haveRightIcon">
      <div class="menuIcon">
        <Burger></Burger>
      </div>
    </span>
    <div class="icon" @click="$emit('right-click', $event)" v-if="haveRightText">
      <p>{{ rightText }}</p>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import Burger from "./Menu/Burger";
import Sidebar from "./Menu/Sidebar";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    Burger,
    Sidebar
  },
  props: {
    title: String,
    haveRightIcon: Boolean,
    haveRightText: Boolean,
    rightText: String
  },
  computed: {
    ...mapGetters("authentication", ["isUserLoggedIn"]),
    ...mapState("authentication", ["user"]),
    ...mapState("app", ["networkOnLine", "appTitle", "appShortTitle"])
  },
  methods: {
    async logout() {
      await firebase.auth().signOut();
      localStorage.removeItem("user");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid;
  border-width: thin;
  border-color: lightgrey;
  background-color: #ffffff;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  padding-top: 10px;

  .title {
    font-family: $header-title;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    margin-left: 20px;
  }

  .line1 {
    border-top: 1px solid;
    width: 20px;
  }

  .line2 {
    border-top: 1px solid;
    margin-top: 5px;
    margin-left: 5px;
    width: 10px;
  }

  .line3 {
    border-top: 1px solid;
    width: 5px;
    margin-top: 5px;
    margin-left: 7px;
  }

  .icon {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    align-items: center;
    text-align: center;
  }

  .menuIcon {
    z-index: 9999;
  }
}
</style>
