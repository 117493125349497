<template>
  <div>
    <div>
      <div :style="cssProps">
        <div class="color-overlay"></div>

        <div :style="containerCssProps" class="container">
          <div class="home-navigation">
            <div class="logo-container">
              <div class="logo">
                <img src="@/assets/logo.png" class="logo" />
              </div>
              <div class="headingContainer">
                <p class="logo-heading">Mednosis</p>
              </div>
            </div>
            <div v-if="!isInProdStaging" class="menuIcon">
              <Burger color="#fff" />
            </div>
          </div>
          <div class="home-body">
            <h4
              class="heading1"
            >Welcome to Mednosis, where board-certified physicians and surgeons provide second opinions.</h4>
            <h2 class="heading2">Don't Google. Ask Our Doctors.</h2>
            <div class="btn-container">
              <router-link v-if="!user" to="/experts">
                <button class="btn">
                  CHAT WITH A MEDICAL EXPERT
                  <br />
                  <img src="../assets/mednosis-private-chat.png" /> (Private and Anonymous)
                </button>
              </router-link>
              <router-link
                v-else
                :to="['expert', 'expert'].includes(user.type) ? '/expert-queries' : '/queries'"
              >
                <button class="btn">
                  <img src="../assets/mednosis-private-chat.png" />My Private Queries
                </button>
              </router-link>
            </div>
            <br />
            <br />
            <br />
            <div class="btn-container">
              <a href="https://blog.mednosis.com" target="_blank">Check out our blogs.</a>
              <div>
                <br />
              </div>
            </div>
            <br />
            <br />
            <br />
            <!-- <div class="btn-container"></div>
            <br />
            <br />
            <br />-->
            <div>
              <!-- Begin Mailchimp Signup Form -->
              <div id="mc_embed_signup">
                <form
                  action="https://mednosis.us19.list-manage.com/subscribe/post?u=984073d61a6ab620a5131b56f&amp;id=f2f456f93f"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_blank"
                  novalidate
                >
                  <div id="mc_embed_signup_scroll">
                    <label for="mce-EMAIL">Subscribe and get promotional offers.</label>
                    <input
                      type="email"
                      value
                      name="EMAIL"
                      class="email"
                      id="mce-EMAIL"
                      placeholder="email address"
                      required
                    />
                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div style="position: absolute; left: -5000px;" aria-hidden="true">
                      <input
                        type="text"
                        name="b_984073d61a6ab620a5131b56f_f2f456f93f"
                        tabindex="-1"
                        value
                      />
                    </div>
                    <div class="clear">
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <!--End mc_embed_signup-->
            </div>
            <div class="expertjoinlink">
              <router-link :to="'/expertcontact'">Are you a medical professional?</router-link>
            </div>
          </div>
          <span></span>
        </div>
        <Sidebar v-if="isMobile" />
      </div>
    </div>

    <div style="position: absolute">
      <div class="main">
        <section>
          <img src="../assets/Chat-image.jpg" />

          <div>
            <h2>Secure and Confidential Patient-Doctor Communication</h2>
            <p class="Para">
              Mednosis provides secure and confidential doctor-patient
              chat. Ask any medical question you may have with confidence. We
              adhere to HIPPA’s requirements for confidentiality and our physicians
              are HIPPA compliant. With Mednosis your questions are secure,
              and confidential.
            </p>
          </div>
        </section>
        <section>
          <img src="../assets/certified-image.jpg" />

          <div>
            <h2>Conveniently Access Board Certified US Physicians and Surgeons</h2>
            <p class="Para">
              Physicians with Mednosis are board-certified in the US and have their own medical practices.
              Through our platform, they offer second opinion consultations to answer your pressing medical questions.
              You can also access specialists to request information about your condition.
            </p>
          </div>
        </section>
        <section>
          <img src="../assets/visit.jpg" />

          <div>
            <h2>Stop Googling. Use Trusted & Verified Mednosis.</h2>
            <p
              class="Para"
            >Whether your condition doesn’t warrant an in-person visit with your primary care physician or if you’re looking for a specific answer to a unique medical question, Mednosis is a trusted and verified resource. Contact a physician who has the experience, education, and skill necessary to accurately answer your important medical questions.</p>
          </div>
        </section>
        <section>
          <img src="../assets/how-we-work.jpg" />

          <div>
            <h2>How Mednosis Works</h2>
            <p class="Para">
              Mednosis is not telemedicine. You won’t receive medical prescriptions or diagnoses from our physicians. What you can expect is trusted, medically sound second opinions from practicing physicians and surgeons.
              Once you set up your account, you can contact any physician on our platform. Physicians will respond within 12 hours to ensure your question is answered.
              Please keep in mind the information you receive on Mednosis is meant for educational purposes only. Search engines can turn up unreliable, and oftentimes frightening, results.
              Avoid unnecessary stress by speaking with a medical professional qualified to answer your question, when you use Mednosis.
            </p>
          </div>
        </section>
        <section>
          <img src="../assets/chat-with-doc.jpg" />

          <div>
            <h2>Chat With a Physician Today!</h2>
            <p
              class="Para"
            >Take advantage of low-cost physician consultations from Mednosis and get answers to your pressing medical questions, when you sign up.</p>
          </div>
        </section>
      </div>
      <footer class="Footer">
        <div>
          <router-link to="/terms">
            <a>Terms</a>
          </router-link>|
          <router-link to="/privacy">
            <a>Privacy Policy</a>
          </router-link>|
          <router-link to="/investors">
            <a>Investors</a>
          </router-link>
        </div>
        <div>© 2020 Mednosis LLC. All rights reserved.</div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Burger from "@/components/Menu/Burger.vue";
import Sidebar from "@/components/Menu/Sidebar.vue";
import ExpertListing from "@/components/ExpertListing-general.vue";

export default {
  components: {
    Sidebar,
    Burger,
    ExpertListing,
  },
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require("../assets/Optimized-Image.png")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

        width: "100%",
        minHeight: "100vh",
      },

      containerCssProps: {
        width: "100%",
        minHeight: "100vh",
      },
    };
  },
  head() {
    return {
      title: {
        inner: "Chat with physicians, Get second opinions and advice at a low cost.",
      },
      meta: [
        {
          name: "description",
          content: "Chat with physicians, Get second opinions and advice at a low cost.",
        },
        {
          name: "keywords",
          content:
            "medical opinion, question, Ask a doctor, Ask a Physician, second opinion,diagnosis, online doctor, online physicians, online expert advice, symptoms, infections, coughing, sneezing, fever, pain, muscle pain, weakness, dizziness, cancer, breast cancer, surgery, medication",
        },

        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Chat with experts, Get advice at very low cost.",
        },
        { property: "og:site_name", content: "Mednosis" },
        // The list of types is available here: http://ogp.me/#types
        { property: "og:type", content: "website" },
        // Should the the same as your canonical link, see below.
        { property: "og:url", content: "https://mednosis.com/" },
        {
          property: "og:image",
          content:
            "https://storage.googleapis.com/med-prod.appspot.com/app/images/mednosis-com-online-experts.jpg",
        },
        // Often the same as your meta description, but not always.
        {
          property: "og:description",
          content: "Chat with experts, Get advice at very low cost.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://mednosis.com/" },
        {
          name: "twitter:title",
          content: "Chat with experts, Get advice at very low cost.",
        },
        {
          name: "twitter:description",
          content: "Chat with experts, Get advice at very low cost.",
        },
        // Your twitter handle, if you have one.
        { name: "twitter:creator", content: "@mednosis" },
        {
          name: "twitter:image:src",
          content:
            "https://storage.googleapis.com/med-prod.appspot.com/app/images/mednosis-com-online-experts.jpg",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Chat with experts, Get advice at very low cost.",
        },
        {
          itemprop: "description",
          content: "Chat with experts, Get advice at very low cost.",
        },
        {
          itemprop: "image",
          content:
            "https://storage.googleapis.com/med-prod.appspot.com/app/images/mednosis-com-online-experts.jpg",
        },
      ],
    };
  },
  computed: {
    ...mapState("app", ["appTitle", "isMobile", "isInProdStaging"]),
    ...mapState("authentication", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.Footer {
  text-align: center;
  margin: 20px 0;
}
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 30px;
  @media screen and (max-width: 425px) {
    padding: 0px;
    margin: 10px;
    margin-top: -20px;
  }
  margin-top: -30px;
  flex-wrap: wrap;
  background: transparent;
  background: #fff;
  color: #777;
  box-shadow: 0px 0px 10px #777;
  > hr {
    flex: 1;
  }
  > section {
    display: flex;
    align-items: stretch;
    border-bottom: 2px solid #efefef;
    &:last-child {
      border-bottom: 0px;
    }
    > img {
      width: 33%;
    }
    @media screen and (max-width: 1024px) {
      border-bottom: 0px;
      flex-direction: column;
      > img {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    > div {
      // margin-left: 20px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 30px;
      h2 {
        max-width: 400px;
        margin: 0;
        font-size: 26px;
      }

      p {
        margin: 0;
        font-size: 20px;
      }
    }
    overflow: hidden;
    padding: 20px;
    background: transparent;
    z-index: 1;
    width: calc(100% / 1 - 40px);
    @media screen and (max-width: 1024px) {
      width: calc(100% / 2 - 40px);
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sidebar-panel-nav {
    list-style: none;
  }

  .list-container {
    // background-color: #00abdc;
    margin-top: 10px;
    border-bottom: solid;
    border-width: thin;
    padding-bottom: 10px;
  }

  .socialIconsContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  .list-text {
    font-family: $header-title;
    font-size: 24px;
  }

  .home-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    width: 100%;
  }

  .logo-container {
    display: flex;
    flex-direction: "column";
    margin-left: 20px;
  }

  .logo {
    align-self: center;
    width: 50px;
  }
  .logo-image2 {
    position: relative;
    top: 10px;
    right: 10px;
  }

  .logo-image3 {
    position: relative;
    right: 20px;
    top: 5px;
  }

  .logo-heading {
    // margin-left: 5px;
    margin-right: 10px;
    font-family: $header-title;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #fff;
  }

  .headingContainer {
    align-self: center;
  }

  .menuIcon {
    margin-right: 20px;
    align-self: center;
  }

  .heading1 {
    font-family: Shanti;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0px 16px 24px rgba(50, 50, 71, 0.08),
      0px 12px 12px rgba(50, 50, 71, 0.08);
  }

  .expertjoinlink {
    font-family: Shanti;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 50px;
    color: rgb(144, 220, 243);
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0px 16px 24px rgba(50, 50, 71, 0.08),
      0px 12px 12px rgba(50, 50, 71, 0.08);
  }

  .heading2 {
    font-family: Source Sans Pro;
    font-style: normal;
    // font-weight: 600;
    font-size: 33px;
    line-height: 41px;
    text-align: center;
    margin-right: 20px;
    margin-left: 20px;
    color: #ffffff;
    text-shadow: 0px 16px 24px rgba(50, 50, 71, 0.08),
      0px 12px 12px rgba(50, 50, 71, 0.08);
  }
  .heading3 {
    font-family: Source sans Pro;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin: 0 20px;
    text-shadow: 0px 4px 8px rgba(50, 50, 71, 0.06),
      0px 4px 4px rgba(50, 50, 71, 0.08);
  }

  .btn {
    background-color: #00abdc;
    font-family: Source sans Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 15px;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  .btn-container {
    text-align: center;
  }
  a {
    color: #fff;
  }
}
</style>
