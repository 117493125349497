import { auth } from "firebase";
import UsersDB from "@/firebase/users-db";
import { AUthDetailConvertingLayer } from "@/utils";
/**
 * Create new user from firebase auth user infos
 */
// eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async (
  firebaseAuthUser,
  data
) => {
  const userDb = new UsersDB();
  if (!data) {
    console.log(firebaseAuthUser.providerData);
    const providerData = firebaseAuthUser.providerData[0];
    const { displayName, photoURL, email, providerId } = providerData;
    const user = {
      type: "patient",
      email: email,
      firstName: `User${firebaseAuthUser.uid.slice(0, 4)}`,
      lastName: "",
      dob: "",
      city: "",
      country: "",
      ethinicity: "",
      gender: "",
      avatar: ""
    };
    return userDb.create(user, firebaseAuthUser.uid);
  } else {
    const {
      avatar,
      dob,
      city,
      country,
      email,
      ethinicity,
      gender,
      type,
      firstName,
      lastName
    } = data;
    return userDb.create(
      {
        avatar,
        dob,
        city,
        country,
        email,
        ethinicity,
        gender,
        type,
        firstName,
        lastName
      },
      firebaseAuthUser.uid
    );
  }
};
