<template>
  <div class="tabContainer">
    <img src="@/assets/Union.png" class="plus" />
    <router-link to="/queries">
      <img src="@/assets/home.png" class="icon" />
    </router-link>
    <!-- <router-link to="/add-query">
      <img src="@/assets/Rectangle.png" />
    </router-link> -->
    <router-link to="/notification">
      <img src="@/assets/notification.png" class="icon" />
    </router-link>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters("authentication", ["isUserLoggedIn"]),
    ...mapState("authentication", ["user"]),
    ...mapState("app", ["networkOnLine", "appTitle", "appShortTitle"])
  },
  methods: {
    async logout() {
      await firebase.auth().signOut();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.tabContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-top: solid;
  border-width: thin;
  border-color: lightgray;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  .icon {
    width: 20px;
    height: 20px;
  }

  .plus {
    position: absolute;
    bottom: 37px;
  }
}
</style>
