<template>
  <div @click="$emit('select')" class="card">
    <h3>{{ title }}</h3>
    <p  class="description" >{{ description }}</p>
    <p class="status">{{ status }}</p>
    <p class="status">
      {{ expertsCount }} expert(s) assigned.
    </p>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    title: String,
    description: String,
    status: String,
    expertsCount: Number
  },
  computed: {
    ...mapGetters("authentication", ["isUserLoggedIn"]),
    ...mapState("authentication", ["user"]),
    ...mapState("app", ["networkOnLine", "appTitle", "appShortTitle"])
  },
  methods: {
    async logout() {
      await firebase.auth().signOut();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.card {
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
  border-bottom: solid;
  border-width: thin;
  border-color: lightgrey;

  .status {
    text-align: right;
    font-style: normal;
    font-weight: 200;
    font-size: 11px;
    line-height: 18px;
    text-align: right;
    color: #000000;
  }

  .description{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
