export default {
  appTitle: process.env.VUE_APP_TITLE,
  appShortTitle: process.env.VUE_APP_SHORT_TITLE,
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  refreshingApp: false,
  isInProdStaging: window.location.hostname === "med-prod.firebaseapp.com",
  usePaypalGateway: false,
  isNavOpen: false,
  isMobile: true
};
