// import firebase from "firebase/app";
// import "firebase/auth";
// const cfg = {
//   apiKey: "AIzaSyAP0k1rxGPANh1D95JJwC6yvvJqiTsnuW4",
//   authDomain: "med-prod.firebaseapp.com",
//   databaseURL: "https://med-prod.firebaseio.com",
//   messagingSenderId: "173552780704",
//   projectId: "med-prod",
//   storageBucket: "med-prod.appspot.com"
//     }
// console.log(cfg.projectId);
// firebase.initializeApp(cfg);
import firebase from "firebase/app";
import "firebase/auth";
const cfg =
  process.env.VUE_APP_FIREBASE_ENV == "production"
    ? {
      apiKey: "AIzaSyAP0k1rxGPANh1D95JJwC6yvvJqiTsnuW4",
      authDomain: "med-prod.firebaseapp.com",
      databaseURL: "https://med-prod.firebaseio.com",
      messagingSenderId: "173552780704",
      projectId: "med-prod",
      storageBucket: "med-prod.appspot.com"
    }
    :  {
        apiKey: "AIzaSyAP0k1rxGPANh1D95JJwC6yvvJqiTsnuW4",
        authDomain: "med-prod.firebaseapp.com",
        databaseURL: "https://med-prod.firebaseio.com",
        messagingSenderId: "173552780704",
        projectId: "med-prod",
        storageBucket: "med-prod.appspot.com"
      };
console.log(cfg.projectId);
firebase.initializeApp(cfg);