import { auth, firestore } from "firebase";
import { isNil, isNull } from "lodash";
import router from "@/router";
import { createNewUserFromFirebaseAuthUser } from "@/misc/helpers";
import UsersDB from "@/firebase/users-db";
import {
  callGetAPIWithNoAuth,
  createUser
} from "@/utils";

export default {
  /**
   * Callback fired when user login
   */
   login: async ({ commit, dispatch }, firebaseAuthUser) => {
    var data = JSON.stringify({"requestedUserId": firebaseAuthUser.uid});
    const userFromFirebase = await callGetAPIWithNoAuth(`usersna`, null, data);
    var val = userFromFirebase[0];
    console.log(val);
    const user = isNull(val)
        ? await createUser(firebaseAuthUser.uid, firebaseAuthUser)
        : val;
    commit("setUser", { ...user, uid: firebaseAuthUser.uid });
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit("setUser", null);
    // commit('products/setProducts', null, { root: true })

    const currentRouter = router.app.$route;
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push("/ask-auth");
    }
  }
};
